import $ from 'jquery';  
window.jQuery = $; window.$ = $

import OpenSeadragon from 'openseadragon';
import Tooltip from './tooltip';

const pagination_observer = new IntersectionObserver((entries, self) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const container = entry.target      
      $('a[rel=next]', $(container)).trigger('scroll_click');
     
      self.unobserve(container);
    }
  })
}, {rootMargin: "0px 0px 200px 0px"});


// const image_observer = new IntersectionObserver((entries, self) => {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       const lazyImage = entry.target
//       lazyImage.src = lazyImage.dataset.src
//       lazyImage.classList.remove("lzy_img");
//       self.unobserve(lazyImage);
//     }
//   })
// }, {rootMargin: "0px 0px 0px 0px"});


function create_viewer_with_url(url) {
  let viewer = OpenSeadragon({
    id: "original-img",
    tileSources: {
      type: 'image',
      url:  url
    },
    showNavigationControl: false
  });
  return viewer;
}

$(document).on('click', ".artifact-image-selector button", function(e){   
  var $this = jQuery(this), 
      img = $this.children('img');
  
  jQuery('.artifact-image', $this.parents('.artifact-images')).html(img.data('html'));  
})

$(document).on('click', ".artifact .artifact-image img", function(e){   
  
  var $this = jQuery(this),
      bg = jQuery("<div id='overlay'><div id='original-img'></div><button class='close'></button></div>").appendTo('body'),
      images = jQuery('.artifact-image-selector img', $this.parents('.artifact-images:first'));

  jQuery('body').css('overflow','hidden');
  
  var url = this.tagName == 'IMG' ? $this.data('url') : $this.prev().data('url');
  var viewer = create_viewer_with_url(url);

  bg.data('i', $this.data('i'))
    
  // Add pagination
  if(images.length > 1) {
    $('<button type="button" rel="prev"></button><button type="button" rel="next"></button>')
      .appendTo(bg)
      .each(function(){
        jQuery(this).on('click', function(){
          
          var $btn = $(this),
              current_i = parseInt(bg.data('i'), 10),
              i = 0;
                                  
          if ($btn.attr('rel') == 'next') {
            i = (current_i < (images.length - 1)) ? (current_i + 1) : 0;
          } else {
            i = (current_i > 0) ? (current_i - 1) : (images.length - 1);
          }

          viewer.open({
            type: 'image',
            url: images.eq(i).data('url'),
            crossOriginPolicy: 'Anonymous',
            ajaxWithCredentials: false
          })          

          bg.data('i', i);
        })        
      })
  }
  
    
 
  // Close
  jQuery('button.close', bg).on('click', function(){
    bg.remove();
    jQuery('body').css('overflow','auto');
  });
  
  e.preventDefault();
});



// Lazy load project images
document.addEventListener("DOMContentLoaded", function() {
  const lzy_images_links = document.querySelectorAll('.lzy-image-link')
  
  lzy_images_links.forEach((lzy_images_link) => {
    decorate_lzy_image_link(lzy_images_link)    

  })  
  
})

function decorate_lzy_image_link(node) {
  
    const img = node.querySelector('img');

    function loaded() {
      node.classList.add('loaded');
    }

    if (img.complete) {
      loaded()
    } else {
      img.addEventListener('load', loaded)
    }

}


// Lazy load pagination links
document.addEventListener("DOMContentLoaded", function() {
  
  const arr = document.querySelectorAll('#scroll-pagination')
  arr.forEach((v) => {
    pagination_observer.observe(v);
  })

   // Tooltips
   Tooltip('.tooltiped')
})


$(document).on('scroll_click', "a[rel=next]", function(e){
  var $this = $(this),
      c = $('.scroll-container:first');
  
  $('<div class="lds-circle"><div></div></div>').insertAfter($this)
  
  $.get( $this.attr('href'), function(html) {
    var $html = $(html);
    
    $('.scroll-container > *', $html).appendTo(c).find('.lzy-image-link').each(function(){
      
      decorate_lzy_image_link(this)  
    });

    
    
    var pagi = $html.find("#scroll-pagination").replaceAll("#scroll-pagination")
    if (pagi.length > 0) {
      pagination_observer.observe(pagi.get(0));
    }
  });  
});


$(document).on('artifacts:search', '.splide__slide a, nav.filters a, form#search-f', function(e){
  
  var $this = $(this),
      url = '';
  
  if (this.tagName == 'FORM') {
    url = $this.attr('action') + '?' + $this.serialize();
  } else {
    url = $this.attr('href')
  }
  
  window.history.replaceState('', '', url);
  
  $.ajax({
    type: "GET",
    url: url,
    dataType: 'html'
  }).done(function(html) {    
    
    var $html = $(html);
    
    $('#collections li a', $html).each(function(){
      var $this = $(this);
      $this.replaceAll("#" + $this.attr('id'));
    })
    
    $('.artifacts', $html).replaceAll('.artifacts').find('.lzy-image-link').each(function(){
      decorate_lzy_image_link(this)
    });
    
    $('nav.filters', $html).replaceAll('nav.filters');
    Tooltip('nav.filters .tooltiped');
    
    $('form#search-f .hidden-fields', $html).replaceAll('form#search-f .hidden-fields');
    
    $("#scroll-pagination").remove();    
    $("#scroll-pagination", $html).appendTo('main').each(function(){
      pagination_observer.observe(this);
    })

    

  });    
  
})


$(document).on('submit', 'form#search-f.remote', function(e){  
  $(this).trigger('artifacts:search'); 
  e.preventDefault();
});


$(document).on('click', '.splide__slide a, nav.filters a', function(e){  
  $(this).trigger('artifacts:search'); 
  e.preventDefault();
});



