const DELAY = 600
const MARGIN = 10
const SPACER = 4

var timeout = null
var open_tooltip = null
var current_target = null

let showTooltip = function(e) {
  
  const link = e.target
  
  clearTimeout(timeout); 
  
  // Other tooltip open
  if (open_tooltip != undefined && link != current_target) {
    hideTooltip()
  }

  if (open_tooltip == undefined) {
  
    timeout = setTimeout(function() {                

      let x = 0
      let y = 0    

      let tooltip = document.createElement('div')
      tooltip.classList.add('tooltip')
    
      tooltip.innerHTML = link.getAttribute('aria-label')


      document.querySelector('body').appendChild(tooltip)   

      let bound_rect = link.getBoundingClientRect();

      x = bound_rect.left + (link.offsetWidth / 2) - (tooltip.offsetWidth / 2)
      y = bound_rect.top + link.offsetHeight + SPACER + window.scrollY

      // Over or under
      if ((y + tooltip.offsetHeight) > (window.scrollY + document.documentElement.clientHeight)) {
        y = bound_rect.top - tooltip.offsetHeight - (SPACER * 2)       
      } 

      // Right or left        

      if ((x + tooltip.offsetWidth) >= document.documentElement.clientWidth) {
        x = document.documentElement.clientWidth - tooltip.offsetWidth - MARGIN 
      } else if (x < 0) {
        x = MARGIN
      }
    
      tooltip.style.left = x + 'px'
      tooltip.style.top = y + 'px' 

      open_tooltip = tooltip
      current_target = link
    
      open_tooltip.addEventListener('mouseenter', function(e){            
        clearTimeout(timeout);              
      }, DELAY)

      open_tooltip.addEventListener('mouseleave', delayhideTooltip)
    
    
    }, DELAY)      
  }
  
}


let hideTooltip = function(){
  
  clearTimeout(timeout) 
    
  if (open_tooltip != undefined) {
    open_tooltip.remove()              
  } 

  open_tooltip = null
  current_target = null  
}

let delayhideTooltip = function(e) {
  
  clearTimeout(timeout)

  // Tooltip open
  if (open_tooltip != undefined) {
    timeout = setTimeout(function(){
      hideTooltip()
    }, DELAY)
  }
}


function Tooltip(selector) {

  document.querySelectorAll(selector).forEach((node) => {        
    node.addEventListener('mouseenter', showTooltip)    
    node.addEventListener('focus', showTooltip)    
    
    node.addEventListener('click', hideTooltip) 
    node.addEventListener('mouseleave', delayhideTooltip)        
    node.addEventListener('blur', delayhideTooltip)    
  })
}

export default Tooltip
