import $ from 'jquery';  
window.jQuery = $; window.$ = $

import Splide from '@splidejs/splide';

import 'intersection-observer';
import './src/articats';


// Enable or disable container links tabbability
function setLinksTabbableFor(container, is_tabbable) {
  container.querySelectorAll('a, [tabindex]').forEach((node) => {
    node.tabIndex = is_tabbable ? undefined : '-1'
  })
}

// Close menu with ESC
function handleMenuKeybordShorcuts(e) {  
  // ESC
  if (e.keyCode == 27) {
    document.querySelectorAll('header .is-open').forEach((node) => {
      node.classList.remove('is-open')            
    })
    setLinksTabbableFor(document.getElementById('primary-navigation'), false)
    document.removeEventListener('keydown', handleMenuKeybordShorcuts)     
  }   
} 



// $(document).on('click', "header label", function(e){  
//   $(this).toggleClass('checked');                           
// });
// 
// 
// $(document).on('click', "label.toggle", function(e){  
//   $(this).toggleClass('open');    
// });
// 
// $(document).on('change', "#m-s", function(e){
//   if ($(this).prop('checked')) {
//     $('#q').focus();
//   }  
// })  


const prev_observer = new IntersectionObserver((entries, self) => {
  
  const btn = $("#collections button.splide__arrow--prev, #collections .fade.left");
  
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      btn.hide()
    } else {
      btn.show()
    }
  })
}, { threshold: 0.6 });

const next_observer = new IntersectionObserver((entries, self) => {
  
  const btn = $("#collections button.splide__arrow--next, #collections .fade.right");
  
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      btn.hide()
    } else {
      btn.show()
    }
  })
}, { threshold: 0.6 });



$(function() {
  
  // Burger
  const burger = document.getElementById('burger')
  const menu = document.getElementById('primary-navigation')
  burger.addEventListener('click', (e) => {  

    burger.classList.toggle('is-open')
    menu.classList.toggle('is-open')  

    if (burger.classList.contains('is-open')) {
      document.addEventListener('keydown', handleMenuKeybordShorcuts)
      setLinksTabbableFor(menu, true)
    } else {
      document.removeEventListener('keydown', handleMenuKeybordShorcuts)
      setLinksTabbableFor(menu, false)
    }

    e.preventDefault();
  })

  $(document).on('submit', 'form#search-f.remote', function(e){  
    burger.classList.remove('is-open')
    menu.classList.remove('is-open') 
    document.removeEventListener('keydown', handleMenuKeybordShorcuts)
    setLinksTabbableFor(menu, false)
  })

  setLinksTabbableFor(menu, false)

  const lang_btn = document.getElementById('lang-button');
  lang_btn.addEventListener('click', (e) => {  
    lang_btn.classList.toggle('is-open'); 
  })


  $("#collections").each(function(){
    var splide = new Splide('#collections', {
      autoWidth: true,
      arrows: true,
      width: "100%",
      gap: 10, 
    	padding: 24,
      pagination: false,
      keyboard: true,
      slideFocus: false,      
      breakpoints: {
        639: {
          padding: 12,
        }
      }
    })
  
    splide.on( 'mounted', function () {
      document.querySelectorAll('#collections li.splide__slide:first-of-type').forEach((v) => {
        prev_observer.observe(v);
      })
      document.querySelectorAll('#collections li.splide__slide:last-of-type').forEach((v) => {
        next_observer.observe(v);
      })
    });
  
    splide.mount();
  })  
  
  
  $("article a:not(.archive-biography)").filter(function() {
    return this.hostname != window.location.hostname;
  }).addClass('ext').attr('target', '_blank').filter(function() {
    return this.hostname == 'arkivet.thorvaldsensmuseum.dk'
  }).addClass('arc');
  
 


});



